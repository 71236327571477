.layer {
    display: flex;
    align-items: center;
    height: 40px;
    width: 200px;
}

.layer-name {
    width: 150px;
    text-align: right;
    padding-right: 10px;
    font-weight: bold;
}

.layer-parameter {
    font-weight: 500;
    margin-top: -2px;
    opacity: 0.75;
}

.layer-properties {
    position: relative;
    flex-grow: 1;
    height: 100%;
  
}

.keyframeIcon{
    z-index: -1;
}

.keyframe-line-container {
    
    margin-left: 103px;
    margin-top: -12px;
    margin-right: 0px
}

.keyframe-line-container-shape {
  
    margin-left:90px;
    margin-top: -12px;
    margin-right: 6px
}

.keyframeline {
    position: relative;
    height: 5px;
    border-radius: 5px;
    margin-right:16px;
}

.timeline-anim-container {
    position: relative;
    padding: 0px;
    margin-top: 24px;
    margin-bottom: 32px;
}


.tooltip {
    position: absolute;
    background: black;
    color: white;
    font-size: 14px;
    padding: 6px 10px;
    border-radius: 4px;
    opacity: 0.9;
    line-height: 20px;
    transform: translate(-50%, -120%);
    cursor: pointer;
    z-index: 10000;
    min-width: 170px;
    max-width: 350px;
    border: 1px solid rgb(89, 89, 89);
}

.tooltip-down {
    position: absolute;
    background: black;
    color: white;
    font-size: 14px;
    padding: 6px 10px;
    border-radius: 4px;
    opacity: 0.9;
    line-height: 20px;
    transform: translate(-50%, 5%);
    min-width: 170px;
    cursor: pointer;
    z-index: 10000;
    max-width: 350px;
    border: 1px solid rgb(89, 89, 89);
}
.timeline-layer {
    background-color: rgb(14, 14, 14);
    margin-bottom: 6px;
    border: 1px solid rgb(41, 41, 41);
    height: auto;
    padding: 10px;
    border-radius: 10px;
    margin-top: 16px;

  }
.tooltip-inbetween {
    position: absolute;
    background: black;
    width:fit-content;
    color: white;
    font-size: 12px;
    padding: 6px 10px;
    border-radius: 4px;
    line-height: 20px;
    opacity: 0.9;
    transform: translate(-50%,-105%);
    white-space: nowrap;
    pointer-events: none;
    z-index: 10000;

    border: 1px solid rgb(89, 89, 89);
}

.tooltip-inbetween-up {
    position: absolute;
    background: black;
    width:fit-content;
    color: white;
    z-index: 1000;
    font-size: 12px;
    padding: 6px 10px;
    border-radius: 4px;
    line-height: 20px;
    opacity: 0.9;
    transform: translate(-50%,12%);
    white-space: nowrap;
    pointer-events: none;
    z-index: 100;

    border: 1px solid rgb(89, 89, 89);
}

.scroll-btn-code-search{
    font-size:12px;

    background-color: transparent;
    font-weight: 600;
    border-radius: 6px;
    text-decoration: underline;
    /* padding:6px 16px 6px 16px; */
    outline: none;
    border: none;
    color:white;
    color: #d5d0f6;
}
.scroll-btn-code-search:hover{
    filter:brightness(1.2);

}

.keyframe{
    top:4px;
    z-index: 100;
}
/* .tooltip-nubbin {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -6px;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
    background-color: red;
} */

.transition-tooltip {
    position: absolute;
    z-index: 10000;
    transform: translate(50%, -50%);
}



/* 🚀 STRONGER GLOW EFFECT */
.keyframe-segment {
    filter:brightness(1);
    transition: all 0.3s ease;
    background-color: rgba(255, 255, 255, 0.1);
}

/* Glow effect when hovered */
.keyframe-segment:hover {
    filter:brightness(20);
    background-color: rgba(255, 255, 255, 0.8);
    /* box-shadow: 0px 0px 4px 5px rgba(255, 255, 255, 0.2); */
}

/* Even stronger glow when clicked */
.keyframe-segment.glowing {
    filter:brightness(20);
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 0px 10px 4px rgba(255, 255, 255, .18);
}

