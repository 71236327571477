.lottie-collection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-rows: repeat(auto-fit, 300px);
  grid-gap: 20px;
}


  
  .lottie-upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  
    height: inherit;
    border: 2px dashed #666;
    border-radius: 4px;
    font-size: 16px;
    color: #888;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  
  .lottie-upload:hover {
    border-color: rgb(42, 158, 104);
   
  }
  
  .lottie-upload p {
    margin: 0;
  }
  
  .lottie-upload input {
    display: none;
  }
  
 
  
  .lottie-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s ease-in-out;
  }
  
  .lottie-preview:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }

  
  
  .lottie-name {
    margin: 0;
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #333;
  }

  #uploadLabel{
    display: flex;
    color:#f4f4f4;
    margin-bottom: 0px;
  }

  #uploadLabel::before{
    content: '';
    display: inline-block;
    width: 22px;
    height: 22px;
    margin-right: 10px;
    padding-bottom: -50px;
    background-image: url('../Images/Icons/upload.svg');
    background-size: contain;
    background-repeat: no-repeat;

  }
  

  

 
  