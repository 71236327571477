.animation-timeline {
  position: relative;
  height: 100%;
  padding: 20px;
  padding-top: 0px;
  width: 100%;
  background-color: var(--dark-002);
  content-visibility: visible;
}
/* 
#playhead {
  width: 98.6%;
  margin-bottom: 2px;
  background-color: transparent;
} */


/* WebKit (Chrome, Safari) */
#playhead::-webkit-slider-runnable-track {
  width: 100%;
  margin-right: 4px;
  height: 4px;
  background-color: #5c46f0;
  border-radius: 2px;
  border: none;
}

/* Mozilla Firefox */
#playhead::-moz-range-track {
  width: 98.4%;
  height: 4px;
  background-color: #4028db;
  border-radius: 2px;
  border: none;
}

/* Microsoft Edge */
#playhead::-ms-track {
  width: 98.4%;
  height: 4px;
  background-color: #4028db;
  border-radius: 2px;
  border: none;
  color: transparent;
}



.timeline-layer-name {
  font-size: 12px;
  font-weight: 600;
  color: white;
  font-weight: 500;
  margin-bottom: 20px;
}
