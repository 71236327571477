.playControl-wrapper{
  display: block;
 
  padding:20px;
  padding-bottom:10px;
  padding-top: 10px;
  background-color: transparent;
  
}
#spec-main-container{
  display:grid;
  height:100vh;
  /* margin-top:50px; */
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  grid-template-areas: "lottie-preview code-preview"
                       "bottom-container bottom-container";     
}
#playhead {
  width: 97.8%;

  margin-bottom: 2px;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
}

#preview-container{
    position: relative;
    grid-area: "lottie-preview";
    overflow: auto;
    content-visibility: auto;
    background:
    linear-gradient(-90deg, rgba(0,0,0,.05) 1px, transparent 1px),
    linear-gradient(rgba(0,0,0,.05) 1px, transparent 1px), 
    linear-gradient(-90deg, rgba(0, 0, 0, .04) 1px, transparent 1px),
    linear-gradient(rgba(0,0,0,.04) 1px, transparent 1px),
    linear-gradient(transparent 3px, #f2f2f2 3px, #f2f2f2 78px, transparent 78px),
    linear-gradient(-90deg, #aaa 1px, transparent 1px),
    linear-gradient(-90deg, transparent 3px, #f2f2f2 3px, #f2f2f2 78px, transparent 78px),
    linear-gradient(#aaa 1px, transparent 1px),
    #f2f2f2;
    
background-size:
    10px 10px,
    10px 10px,
    80px 80px,
    80px 80px,
    80px 80px,
    80px 80px,
    80px 80px,
    80px 80px;
   
}

#lottie-preview-color-picker{
  position: fixed;
  display: grid;
  place-content: center;
  padding: 6px;
  top: 10px;
  left: 10px;
  width: 26px;
  height: 26px;
  outline: 2px solid rgba(0, 0, 0, 0.18);
  background-color: var(--dark-002);
  z-index: 1;
  border-radius: 100px;
  transition: all .25s cubic-bezier(0,0,0,1);
  overflow: hidden;
}

#lottie-preview{
    /* width: 100%; */
    margin:auto;
    padding-top: 1px;
    height: 100%;
    display: grid;
    place-content: center;
    transform-origin: center;
    transform:scale(1);
    transition: all .5s cubic-bezier(.33,0,.67,1);
    
}

#lottie-preview > svg{
  outline: 2px dotted rgb(110, 34, 241);
}

.magnifyControl{
    position: absolute;
    bottom: 4px;
    display: flex;
    gap:16px;
    place-content: center;
    left: 50%;
    transform: translate(-50%, 0%);
    height: 18px;
    width: 60px;
    z-index: 1;
    background-color: var(--dark-002);
    border-radius: 100px;
    padding: 6px;
    }




#code-preview{
    /* width: 100%; */
    grid-area: "code-preview";
    position: relative;
    display: flex;
    padding: 8px;
    content-visibility: auto;
    height: 100%;
    width: 100%;
    background-color: rgb(30, 30, 30);
}

#bottom-container{
    grid-area: "bottom-container";
    position: relative;
    content-visibility: auto;
    width: 100vw;
    height:100%;
    padding:0px;
    background-color: var(--dark-002);
   
}




#layerpane{
 position: relative;
 padding:16px;
 background-color: rgb(30, 30, 30);
 width: 500px;
 height: 100%;
 overflow-y: scroll;
}

#timeline-container{
  position: relative;
  z-index: 1000;
  display: flex;
  overflow-y:scroll;
  content-visibility: auto;
  width: 100vw;
  height:100%;
  padding:0px;

 
}

#layer-container{
    background-color: #000000;
    margin-bottom: 10px;
    padding:6px
}
#layername{
    color:white;
    font-weight: 900;
}

.layer-parameter{
    color:white;
    margin-left: 16px;
    margin-top:6px;
}



input[type="range"] {
    -webkit-appearance: none;
   }
   
   input[type="range"]:focus {
    outline: none;
   }


   input[type="range"]::-webkit-slider-runnable-track {
    background: var(--primary-color);
    border-radius: 4px;
    height: 5px;
   }
   
   input[type="range"]::-moz-range-track {
    background: rgb(156, 220, 254);
    height: 5px;
   }

   input[type="range"]::-webkit-slider-thumb {
 -webkit-appearance: none;
 height: 15px;
 width: 15px;
 background: #f7f7f7;
 margin-top: -5px;
 border-radius: 20px;
}/* width */
::-webkit-scrollbar {
    width: 5px;
    height:5px;
  }

  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
    border-radius: 10px;

  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(172, 172, 172);
    border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #333;
  }


   

input[type="range"]::-moz-range-thumb {
 height: 15px;
 width: 15px;
 background: pink;
 margin-top: -5px;
 border-radius: 50%;
}

.loading-container {
  position: absolute;
  z-index: 200;
  display: flex;
  border-radius: 8px;
  padding:16px 16px 16px 16px;
  top:50px;
  width: 250px;
  font-weight: 600;
  background-color: var(--primary-color);
  align-items: center;
  justify-content: center;
  animation-name: slide;
  animation-duration: 1s;  
  left:50%;
  top:10%;
  transform: translate(-50%, 0% );
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(.33,0,.1,1);
}

.dots {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.dot {
  width: 4px;
  height: 4px;
  margin: 0 2px;
  background-color: white;
  border-radius: 50%;
  animation: bounce 1.4s infinite both;
}

.dot:nth-child(1) {
  animation-delay: -0.32s;
}

.dot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes slide {
  0%{
    opacity: 0;
    transform: translate(-50%, -50%);
  }
  100% {
    opacity: 1;
    transform: translate0(-50%, 0%);
  }
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
}
