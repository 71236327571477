@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Krona+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap');
:root {
  --primary-color: #388BFD;
  --success-color: #57C15B;
  --secondary-color: #eff1f3;
  --primary-white: #f4f4f4;
  --lottie-bg: #f4f4f4;
  --dark-009: rgb(90, 90, 90);
  --dark-008: rgb(80, 80, 80);
  --dark-007: rgb(70, 70, 70);
  --dark-006: rgb(60, 60, 60);
  --dark-005: rgb(50, 50, 50);
  --dark-004: rgb(40, 40, 40);
  --dark-003: rgb(30, 30, 30);
  --dark-002: rgb(20, 20, 20);
  --dark-001: rgb(10, 10, 10);
  --dark-000: rgb(0, 0, 0);
  --font-size: 16px;
}

html{
  background-color: #171717;;
}

.App {
  text-align: left;
  height:100vh;
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 12px;
  width:100vw;
  /* overflow: hidden; */
  background-color: #171717;
}

body{
  content-visibility: auto;
}


.primary-btn{
  padding-left: 12px;;
  padding-right: 12px;
  color:white;
  background-color: var(--primary-color);
  outline: none;
  border: none;
  border-radius: 10px;;
  font-family: 'DM Sans';
  height: 32px;
  font-weight: 600;
  cursor: pointer;
}

.primary-btn:hover{
  filter: brightness(1.1)
}


.secondary-btn{
  padding-left: 12px;;
  padding-right: 12px;
  color:var(--primary-color);
  border: 2px solid var(--primary-color);
  outline: none;
  border-radius: 10px;
  background-color: transparent;
  height: 32px;
  font-size: 12px;
  font-family: 'DM Sans';
  font-weight: 800;
}

.primary-btn:disabled{
  color: #ccc;
  background-color: #666666;
}

.input-subtext{
  margin-top: 2px;
  margin-left: 4px;
}
